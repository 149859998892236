*[data-v-6ddc5d7f] {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
body[data-v-6ddc5d7f] {
  width: 100vw;
  height: 100vh;
  padding: 1vh;
}
body .wxSecurity[data-v-6ddc5d7f] {
  width: 100vw;
  height: 100vh;
  position: relative;
}
.wxhumanResourceAnalysisCentent[data-v-6ddc5d7f] {
  width: 100%;
  height: calc(100vh - 130px);
  padding: 15px;
  background-color: #f8f8f8;
}
.cententBox[data-v-6ddc5d7f] {
  width: 100%;
  height: 100%;
  background-color: #fff;
  border-radius: 8px;
  padding: 10px;
}
.cententBoxTitle[data-v-6ddc5d7f] {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-block: 15px;
}
.cententBoxTitleText[data-v-6ddc5d7f] {
  width: 23%;
}
.cententBoxTitleInput[data-v-6ddc5d7f] {
  width: 77%;
}
.itemPicker[data-v-6ddc5d7f] {
  width: 35%;
}
.wxhumanResourceAnalysisBottom[data-v-6ddc5d7f] {
  width: 100%;
  height: 70px;
  background-color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
}
.bottomItem[data-v-6ddc5d7f] {
  width: 33%;
  height: 100%;
  text-align: center;
  color: #000;
  padding-top: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.bottomItemImg[data-v-6ddc5d7f] {
  width: 40px;
  padding: 3px;
  border-radius: 10px;
  background-color: #25a2fe;
  margin-top: 10px;
}
.cententTabs[data-v-6ddc5d7f] {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 10px;
}
.cententTabsItem[data-v-6ddc5d7f] {
  padding: 5px 0;
  margin-right: 15px;
}
.tabsAction[data-v-6ddc5d7f] {
  border-bottom: 2px solid #25a2fe;
  color: #25a2fe;
}
.cententBoxContentText[data-v-6ddc5d7f] {
  width: 100%;
  text-align: center;
  color: #25a2fe;
  padding: 10px 0;
}
#div_mobilizationTotalNumber[data-v-6ddc5d7f] {
  width: 100%;
  height: 300px;
}
#div_postInputDistribution[data-v-6ddc5d7f] {
  width: 100%;
  height: 300px;
}
#LDLQXecharts[data-v-6ddc5d7f] {
  width: 100%;
  height: 300px;
}
.tableCentent[data-v-6ddc5d7f] {
  width: 100%;
  margin-top: 20px;
}
.wxVideoTitle[data-v-6ddc5d7f] {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: 10px 4%;
}
.wxVideoTitleInput[data-v-6ddc5d7f] {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 15px 20px;
  height: 40px;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAArIAAABQCAYAAADlapYgAAAFYklEQVR4nO3cX6jeBR3H8ffZnM50ma40nYSpFVqJRDHyARFn2R+T0kpnUN5EUayCIukpQmI9kmRTkaxuoohVmpkYMq3UiCct/HMjFoWYN3oh9gdDcc6dLs6cS/fnoNuOP/Z63ZwHnu9z+Fy+efidMzM7O9t8jSbNVJdUF837QwAAMD//qM6YjntgPscz8w3Z0aTF1dXVJ1/0NAAA2LmHm4vZv+zqcF4hO5q0f7W+Onfpklp7Tr3zuJe+EgAAqp7cWF++tu55qKpHqzOn4+7d2Wd2GbKjSQdX11dnLFtal360Tjp6Ny0GAIAtntpUX72u7ph7sOBf1fum4+7c0f1OQ3Y06bDqpmrl8oPqO6vr+MN382IAANji6Wfq4hvq9r9W9Xj1gem432/vdochO5q0orqlOvHIV9UVq2vFoXtoMQAAbLF5ttbeWDffV9UT1bnTcRuef7fdkB1NekNzEXvMsa+py1fX8oP38GIAANhi82xdtqF+NfeU7Mbq/Om467e9eUHIjiadXG2ojnjLivr2ebVs6V5aDAAAW8xWV/2ufvanqjZVn5iOW//s+4u2PR5NOrW6rTpi5bF1xQUiFgCAhTFTrVlVF46q2q/68Wjy3L+C3fqN7GjSWdU11YGnn1BfP7uWLN77gwEA4Pl+ckddfVs190XtF6bjrpyZnZ1tNOlj1Q+rJR98W33xzFo0s5BTAQDg/113d627ea5kq/Hi3+x/8ZrqB9Xij59SnzujZkQsAAAvMyceVUccUn/4W1WrFlVXVjNrVtWnTlvIaQAAsHPvP+m511v/2Ov8lQsxBQAAXpxFuz4BAICXHyELAMAgCVkAAAZJyAIAMEhCFgCAQRKyAAAMkpAFAGCQhCwAAIMkZAEAGCQhCwDAIAlZAAAGScgCADBIQhYAgEESsgAADJKQBQBgkIQsAACDJGQBABgkIQsAwCAJWQAABknIAgAwSEIWAIBBErIAAAySkAUAYJCELAAAgyRkAQAYJCELAMAgCVkAAAZJyAIAMEhCFgCAQRKyAAAMkpAFAGCQhCwAAIMkZAEAGCQhCwDAIAlZAAAGScgCADBIQhYAgEESsgAADJKQBQBgkIQsAACDJGQBABgkIQsAwCAJWQAABknIAgAwSEIWAIBBErIAAAySkAUAYJCELAAAgyRkAQAYJCELAMAgCVkAAAZJyAIAMEhCFgCAQVpUbapad0vNLvAYAACYr0XVh6unfnFXffPG2qxmAQB4mbrh3udez8zOzjaadHp1Q3XwqW+sb3yolixeqHkAAPBC6++s79669SmCr8zMzs69HE1aWd1UHfaO19cl59aB+y/YTgAA2Or7t9eP/1jV5uqz03Hf2xqyVaNJb61uro5884q67LxatnQhpgIAwNxjr+tuqV/eXdXT1YXTcetry6MF2xpNOq76bXXMcYfXutW1/KC9vBgAgH3eM5tr7a/rlvuqerI6bzruxmfff0HIVo0mHdVczJ5w9KF1+QV15CF7aTEAAPu8jZvqa9fX9O9VPV6dPR13+7Y32w3ZqtGk5dWG6u2HL5v7ZvaYV+/ZwQAA8MTGuujauuehqh6r3jMdd9fz73YYslWjSa9s7r8ZnHbIK2rd+fWm1+6hxQAA7PP+82R96ed1/8NVPVy9azru/u3d7jRkq0aTDqyuqc466IC69CN18ut282IAAPZ5j/23Pv/TevDRqh5oLmIf3NH9LkO2ajRpSfWjavUB+9Xac+qU43fTYgAA9nmP/LvWrJ/7Wd1XvXs67pGdfWZeIVs1mrS4uqr69EvcCQAAO/Ln6r3Tcf/c1eGi+f7G6bhnqs9U33oJwwAAYEdurVbNJ2Kr/gd+exoIXNXNfgAAAABJRU5ErkJggg==);
  background-size: 100% 100%;
  font-size: 14px !important;
  font-weight: 500 !important;
  font-family: "Microsoft YaHei", Arial, sans-serif !important;
  background-color: rgba(29, 102, 189, 0.16);
}
.wxVideoTitleInputIcon[data-v-6ddc5d7f] {
  font-size: 20px !important;
}
.sxtImageUrl[data-v-6ddc5d7f] {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}
.sxtImageUrl img[data-v-6ddc5d7f] {
  width: 100%;
}
.bzxsxtImageUrl[data-v-6ddc5d7f] {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}
.bzxsxtImageUrl img[data-v-6ddc5d7f] {
  width: 100%;
}
.wxVideoTitleItem[data-v-6ddc5d7f] {
  width: 100%;
  padding: 15px 4%;
  position: absolute;
  top: 70px;
  left: 0;
  z-index: 999;
}
.itemTextLiBox[data-v-6ddc5d7f] {
  width: 100%;
  height: 70vh;
  background-image: url(../../static/img/inputItem.9551476e.png);
  background-size: 100% 100%;
  padding: 10px;
}
.itemTextLi[data-v-6ddc5d7f] {
  width: 100%;
  height: 100%;
  padding: 10px 0;
  overflow-y: scroll;
}
.itemText[data-v-6ddc5d7f] {
  width: 100%;
  padding: 10px;
  font-size: 13.997px !important;
  font-weight: 500 !important;
}
.active[data-v-6ddc5d7f] {
  background-color: rgba(29, 102, 189, 0.16);
}